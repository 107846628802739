import _ from 'lodash';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {NavLink} from 'react-router-dom';

import {TextField, Button} from '@partssourceinc/react-ui-core';
import * as NetworkStore from 'stores/Network';
import * as UserStore from 'stores/User';
import * as CartStore from 'stores/Cart';
import {logEvent} from 'utility';

import 'less/login.less';

@withRouter
@connect((state) => ({user: state.user, params: state.params}), _.merge({}, NetworkStore.actionCreators, UserStore.actionCreators, CartStore.actionCreators))
export default class ChangePassword extends React.Component {
    static propTypes = {
        displayTitle: PropTypes.bool,
        ...NetworkStore.ActionShape,
        user: UserStore.StateShape,
    };

    constructor(props) {
        super(props);
        this.state = {
            firstLoad: true,
            showTempPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
            displayTitle: true,
            step: 1,
            errors: {},
            tempPassword: '',
            password: '',
            confirmPassword: '',
            userId: '',
        };
    }

    componentDidMount() {
        const {displayTitle, params, hashPassword, login} = this.props;
        this.setState({displayTitle: (displayTitle) ? displayTitle : true});
        
        if (params.e != null) {
            let values = {
                password: params.e,
            }
            hashPassword(values)
                .then(({response: {data}}) => {
                    if (data.userInfo) {
                        // this.setState({ tempPassword: data.userInfo[1], userId: data.userInfo[0] })
                        this.setState({userId: data.userInfo[0]})
                    } else {
                        this.setState({step: 3});
                    }
                    
                }).catch(err => console.log(err));
        }     
    }

    logWebEvent = (isSuccess) => {
        const {params} = this.props;
        logEvent(params.e ? 'Reset PW' : 'Registration', {
            'Password Changed Successfully': isSuccess ? 'Yes' : 'No',
        });
    }

    validate() {
        let {errors, tempPassword, password, confirmPassword} = this.state;
        errors = {};
        let isErrors = 0;
        this.setState({firstLoad: false});
        if (tempPassword === undefined || (tempPassword === '')) {
            errors.tempPassword = true;
            isErrors++;
        }
        if (password === undefined || (password === '') || password.length < 5 || password.length > 17) {
            errors.password = true;
            isErrors++;
        }
        if (confirmPassword === undefined || confirmPassword === '' || password !== confirmPassword) {
            errors.confirmPassword = true;
            isErrors++;
        }
        this.setState({errors});
        return isErrors < 1;
    }
    changePassword(e) {
        e.preventDefault();
        const {tempPassword, password, confirmPassword, userId} = this.state;
        const {changePassword, history, user, params} = this.props;

        if ((user.info && user.info.contactId && this.validate()) || (userId && this.validate())) {
            let values = {
                email: userId || (user.info && user.info.contactId),
                password: tempPassword,
                newPassword: password,
                confirmPassword: confirmPassword,
            }

            changePassword(values).then(({response: {data}}) => {

                if (params.e && data.result === 0) {

                    history.push(`/login?ak1=${userId}&ak2=${confirmPassword}`);
                    
                } else if (data.result === 0) {
                    this.setState({step: 2});
                } else if (data.result === 2) {
                    this.setState({step: 4});
                } else {
                    this.setState({step: 3});
                }

                this.logWebEvent(data.result === 0);
            });
        } else {
            console.log('user is not set');
        }
    }
    toggleTempPasswordVisibility() {
        const {showTempPassword} = this.state;
        this.setState({showTempPassword: !showTempPassword});
    }

    toggleNewPasswordVisibility() {
        const {showNewPassword} = this.state;
        this.setState({showNewPassword: !showNewPassword});
    }

    toggleConfirmPasswordVisibility() {
        const {showConfirmPassword} = this.state;
        this.setState({showConfirmPassword: !showConfirmPassword});
    }

    handleFieldChange(field, e) {
        let {errors} = this.state;
        if (errors[field] === true) {
            errors[field] = false;
        }
        this.setState({errors, [field]: e.target.value});
    }

    render() {
        const {
            tempPassword, password, confirmPassword, errors, showTempPassword, showNewPassword, step, 
            showPassword, displayTitle, showConfirmPassword,
        } = this.state;
        const {
            logout,
        } = this.props;
        const eyeballClass = showPassword ? 'fa fa-eye-slash security-input-eye show' : 'fa fa-eye-slash security-input-eye';
        return (<div className="login-form" >

            {step === 1
                ? <div className="login-content" style={{marginBottom: '90px'}}>
                    {displayTitle ? <h1>Change Password</h1> : null}
                    <form onSubmit={::this.changePassword}>
                        <div>
                            <TextField autoFocus={true} type={showTempPassword ? 'text' : 'password'} id="tempPassword" tabIndex="1" text={tempPassword} value={tempPassword} onChange={e => this.handleFieldChange('tempPassword', e)} showErrorMessage={(errors.tempPassword)}
                                placeholder="Current Password" label="Current Password" className="security-input" />
                            <i className={eyeballClass} aria-hidden="true" onClick={::this.toggleTempPasswordVisibility} />

                            <TextField type={showNewPassword ? 'text' : 'password'} id="newPassword" tabIndex="2" value={password} onChange={e => this.handleFieldChange('password', e)} showErrorMessage={(errors.password)}
                                placeholder="New Password" label="New Password" className="security-input" />
                            <i className={eyeballClass} aria-hidden="true" onClick={::this.toggleNewPasswordVisibility} />
                            {displayTitle ? <div><div className="change-pass-label" style={{marginTop: '10px'}}>New password must be between 5 and 17 characters long.</div>
                            </div> : null}
                            <TextField type={showConfirmPassword ? 'text' : 'password'} id="confirmPassword" tabIndex="3" value={confirmPassword} onChange={e => this.handleFieldChange('confirmPassword', e)} showErrorMessage={(errors.confirmPassword)}
                                placeholder="Confirm Password" label="Confirm Password" className="security-input" />
                            <i className={eyeballClass} aria-hidden="true" onClick={::this.toggleConfirmPasswordVisibility} />

                            <Button tabIndex="4" secondary={true} type="submit" className="pull-right">SUBMIT</Button>
                            <NavLink onClick={() => logout()} tabIndex="5" style={{marginRight: '10px', marginTop: '35px'}} to="/" className="pull-right not-registered">Cancel</NavLink>
                        </div>
                    </form>
                </div> : '' }
                 
            {step === 4
                ? <div className="login-content" style={{marginBottom: '30px'}}>
                    <h1 style={{marginBottom: '25px'}}>Incorrect Current Password </h1>
                    <NavLink onClick={() => this.setState({step: 1, firstLoad: true})} style={{marginRight: '10px', marginTop: '35px', marginBottom: '30px'}} to="/changepassword" className="pull-left forgot-username">Return to Homepage</NavLink>
                </div>
                : ''}

            {step === 2
                ? <div className="login-content" style={{marginBottom: '30px'}}>
                    <h1 style={{marginBottom: '25px'}}>The password has been changed</h1>
                    <NavLink style={{marginRight: '10px', marginTop: '35px', marginBottom: '30px'}} to="/" className="pull-left forgot-username">Return to Homepage</NavLink>
                </div>
                : ''}

            {step === 3
                ? <div className="login-content" style={{marginBottom: '30px'}}>
                    <h1 style={{marginBottom: '25px'}}>Something went wrong</h1>
                    <NavLink onClick={() => this.setState({step: 1, firstLoad: true})} style={{marginRight: '10px', marginTop: '35px', marginBottom: '30px'}} to="/changepassword" className="pull-left forgot-username">Please try again</NavLink>
                </div>
                : ''}

        </div>);
    }
}
